@font-face {
  font-family: 'Rouble';
  src: url("../../../styles/fonts/Rouble-Regular.eot");
  src: url("../../../styles/fonts/Rouble-Regular.eot") format('embedded-opentype'), url("../../../styles/fonts/Rouble-Regular.woff2") format('woff2'), url("../../../styles/fonts/Rouble-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
html,
body {
  padding: 0;
  margin: 0;
}
html {
  font-family: Arial, Helvetica, sans-serif;
  color: #333;
  min-height: 100%;
  position: relative;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  overflow-y: scroll;
}
img {
  border: 0;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
a {
  color: inherit;
  text-decoration: none;
}
*::-ms-clear {
  display: none;
}
.wrapper {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 1140px;
}
.content {
  margin-left: -15px;
  margin-right: -15px;
}
.content:after {
  content: '';
  display: table;
  clear: both;
}
.content_inner {
  min-height: 1px;
  float: left;
  width: 1110px;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
}
:global .r {
  font-family: 'Rouble';
}
:global .test {
  stroke-width: 2px;
}
