height = 40px
padding = 35px

.login
  display: block
  text-align: center
  color: $dark-blue
  fill: $dark-blue
  transition: all .1s ease-out
  font-size: 12px
  font-weight: bold
  padding: 12px 0 10px

  &:hover
    color: $dark-red
    fill: $dark-red

  &_icon
    margin-right: 8px

  &_text, &_icon
    display: inline-block
    vertical-align: middle

.wrapper
  position: relative
  text-align: left
  height: height

.popup
  position: absolute
  width: 100%
  border: 1px solid transparent
  z-index: 100
  background: $white
  transition: border .1s ease-out

  &_active
    border-color: $grey200

  &_content
    overflow: hidden
    max-height: 0
    opacity: 0
    transition: all .1s ease-out

    &_active
      max-height: 160px
      opacity: 1

.header
  font-size: 12px
  font-weight: bold
  color: $dark-blue
  height: height
  line-height: height
  position: relative
  padding-left: padding
  transition: all .1s ease-out
  cursor: pointer

  &:hover
    color: $red

    .icon
      fill: $dark-red

    .arrow:before
      border-color: $red transparent

.icon
  position: absolute
  left: 9px
  top: 10px
  fill: $dark-blue
  transition: all .1s ease-out

.arrow
  position: absolute
  right: 13px
  top: 1px
  width: 6px
  height: height - 2px
  transition: all .1s ease-out

  /.popup_active &
    transform: rotate(-180deg)

  &:before, &:after
    content: ' '
    display: block
    position: absolute
    left: 0
    top: 18px
    height: 0
    width: 0
    border-style: solid
    border-width: 3px 3px 0

  &:before
    border-color: $dark-blue transparent

  &:after
    border-color: #fff transparent
    margin-top: -1px

.email
  line-height: 28px
  margin-bottom: 9px
  padding-left: padding
  color: $red
  font-size: 13px

  &_inner
    overflow: hidden
    text-overflow: ellipsis


.menu
  padding: 8px 0
  border-top: 1px solid $grey200

.item
  display: block
  font-size: 13px
  line-height: 26px
  padding-left: padding
  transition: all .1s ease-out
  
  &:hover
    background: $light-blue
