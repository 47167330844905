.tabs:after {
  content: '';
  display: table;
  clear: both;
}
.tab {
  height: 29px;
  line-height: 29px;
  width: 165px;
  text-align: center;
  font-size: 13px;
  color: #333;
  float: left;
  background: #bdbaba;
  cursor: pointer;
  transition: background 0.1s ease-out;
}
.tab:hover {
  background: #e7eef2;
}
.tab_active {
  background: #e0e0e0;
}
.tab_active:hover {
  background: #e0e0e0;
}
.tab:first-child:after {
  content: ' ';
  display: block;
  float: right;
  width: 1px;
  height: 29px;
  background: #adadad;
}
