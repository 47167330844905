.phone {
  position: relative;
}
.free {
  position: absolute;
  right: 0;
  top: 100%;
  font-size: 11px;
  margin-top: -3px;
}
