$height = 80px

.header
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: $height
  background: #fff
  box-shadow: 0 2px 1px rgba(0,0,0,.2)
  z-index: 1000
  transition: all .2s ease-out
  margin-top: -($height + 3)
  pointer-events: none
  opacity: 0

  @supports (transform: translateY(0)) {
    margin-top: 0
    transform: translateY(-103%)
  }

.active
  margin-top: 0
  opacity: 1
  pointer-events: all
  @supports (transform: translateY(0)) {
    transform: translateY(0)
  }

.wrapper
  wrapper()
  display: flex;
  align-items center

.logo
  padding-top: 6px
  margin-right: auto

.logoImage
  width: 200px
  height: 75px
  display: block
  background: 50% 50% no-repeat
  background-size: contain
  margin-top: -8px

.phone
  font-size: 22px
  margin-right: 15px

  &_icon, &_text
    display: inline-block
    vertical-align: middle

  &_icon
    width: 23px
    height: 23px
    fill: $dark-blue
    margin-right: 8px

.contacts
  display: flex;
  align-items: center

.cart
  margin-left: 30px
