$height = 99px

.footer_placeholder
  margin-top: 28px
  height: $height

.footer
  background: $grey800
  position: absolute
  bottom: 0
  left: 0
  min-width: 100%
  overflow hidden
  padding-left: 10px
  padding-top: 10px
  transition: all .1s ease-out

.contact
  font-size: 12px
  line-height: 1.2
  color: #fff
  padding-bottom: 9px
  padding-left: column-gutter * 2
  position: relative

.icon
  position: absolute
  left: 0
  top: 0
  fill: $white

.mail
  top: 2px

.geo
  left: 1px