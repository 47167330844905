$height = 200px

.footer_placeholder
  margin-top: 55px
  height: $height

.footer
  height: $height
  background: $dark-red
  position: absolute
  bottom: 0
  left: 0
  min-width: 100%
  overflow hidden

  //&:before
  //  content: ' '
  //  background: $red
  //  height: 87px
  //  width: 900px
  //  position: absolute
  //  transform: rotate(-10deg)
  //  right: -14px
  //  bottom: -15px

.wrapper
  wrapper()
  padding-top: 32px
  position: relative
  z-index 1

.container
  display: flex;
  align-items flex-start

.contacts
  span(3)
  //padding-left: column-width

.contact
  font-size: 13px
  line-height: 1.2
  color: #fff
  padding-bottom: 14px
  padding-left: column-gutter * 2
  position: relative

.icon
  content: ' '
  display: block
  position: absolute
  left: 0
  fill: $white

.icon2
  content: ' '
  display: block
  position: absolute
  left: -20px
  fill: $white


.mail
  margin-top: 4px

.socials
  span(2)
  //padding-top: 10px

.social
  display: inline-block
  vertical-align: middle
  margin-right: 20px

.copyright
  float: right
  font-size: 13px
  color: #fff
  white-space: pre
  line-height: 2

.phone
  font-size: 18px
  font-weight: bold
  margin-bottom: 15px

.disclaimer
  color: #ffffff
  font-size: 10px
  margin-top: 8px

.button
  border: 0
  background-color: transparent
  color: #ffffff
  font-size: 13px
  cursor: pointer
  outline: 0

  &:hover
    text-decoration: underline
