.footer_placeholder {
  margin-top: 55px;
  height: 200px;
}
.footer {
  height: 200px;
  background: #1c73c4;
  position: absolute;
  bottom: 0;
  left: 0;
  min-width: 100%;
  overflow: hidden;
}
.wrapper {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 1140px;
  padding-top: 32px;
  position: relative;
  z-index: 1;
}
.container {
  display: flex;
  align-items: flex-start;
}
.contacts {
  min-height: 1px;
  float: left;
  width: 255px;
  margin-left: 15px;
  margin-right: 15px;
}
.contact {
  font-size: 13px;
  line-height: 1.2;
  color: #fff;
  padding-bottom: 14px;
  padding-left: 30px;
  position: relative;
}
.icon {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  fill: #fff;
}
.icon2 {
  content: ' ';
  display: block;
  position: absolute;
  left: -20px;
  fill: #fff;
}
.mail {
  margin-top: 4px;
}
.socials {
  min-height: 1px;
  float: left;
  width: 160px;
  margin-left: 15px;
  margin-right: 15px;
}
.social {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}
.copyright {
  float: right;
  font-size: 13px;
  color: #fff;
  white-space: pre;
  line-height: 2;
}
.phone {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}
.disclaimer {
  color: #fff;
  font-size: 10px;
  margin-top: 8px;
}
.button {
  border: 0;
  background-color: transparent;
  color: #fff;
  font-size: 13px;
  cursor: pointer;
  outline: 0;
}
.button:hover {
  text-decoration: underline;
}
