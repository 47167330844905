.link
  display: inline-block
  vertical-align: top
  font-size: 13px
  color: $black
  text-decoration: none
  margin-left: 15px

  &:hover, &_active
    color: $red

  &:first-child
    margin-left: 0
