.link {
  text-decoration: none;
}
.icon {
  fill: #306bb3;
  display: inline-block;
  vertical-align: middle;
}
.icon_small {
  width: 30px;
  height: 28px;
}
.icon_medium {
  width: 37px;
  height: 31px;
}
.count {
  font-size: 13px;
  font-weight: bold;
  color: #333;
  text-align: center;
  width: 36px;
  line-height: 26px;
  border: 1px solid #adadad;
  border-radius: 4px;
  margin: 0 5px;
  display: inline-block;
  vertical-align: middle;
}
.summary {
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
}
