.button
  display: block
  position: fixed
  width: 52px
  height: 52px
  border-radius: 50%
  bottom: 8px
  right: 8px
  transition: all .1s ease-out
  border: 3px solid rgba($white, .5)
  overflow: hidden

  &:active
    border: 0

.inner
  width: 100%
  height: 100%
  background: $green

.icon
  fill: $white
  width: 21px
  height: 21px
  position: absolute
  left: 50%
  top: 50%
  margin-left: -10px
  margin-top: -10px