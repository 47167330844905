.menu
  text-align: justify
  font-size: 1px
  line-height: 1

  &:after
    content: ''
    display: inline-block
    vertical-align: top
    width: 100%
    height: 0
    visibility: hidden
    overflow: hidden