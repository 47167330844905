.wrapper
  position: relative
  cf()

.tab
  height:29px
  line-height: 29px
  width: 165px
  text-align: center
  font-size: 13px
  color: $black
  float: left
  background: $grey600
  cursor: pointer
  transition: background .1s ease-out

  &:hover
    background: $light-blue

  &_active
    background: $grey200

    &:hover
      background: $grey200

  &:first-child:after
    content: ' '
    display: block
    float: right
    width: 1px
    height: 29px
    background: $grey500

.box
  background: $grey200
  padding: 15px 17px 18px

.search_wrapper
  width: 79%
  display: inline-block
  vertical-align: middle
  position: relative

.search_input
  display: block
  width: 100%
  font-size: 16px
  line-height: 34px
  height: 34px
  padding: 0 19px
  background: #fff
  color: $black
  border: 1px solid $grey500
  border-top-width: 2px
  border-radius: 4px
  outline: 0

.search_button_wrapper
  width: 21%
  padding-left: 17px
  display: inline-block
  vertical-align: middle

.suggest
  position: absolute
  left: 0
  top: 100%
  width: 100%
  border: 1px solid $grey500
  border-radius: 4px
  overflow: hidden
  background: $white
  z-index: 10
  text-align: left

  &_item
    height: 26px
    line-height: 26px
    font-size: 13px
    padding-left: 11px
    transition: all .1s ease-out
    cursor: pointer

    &.hover
      background: $light-blue
