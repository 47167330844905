.wrapper {
  position: relative;
}
.wrapper:after {
  content: '';
  display: table;
  clear: both;
}
.tab {
  height: 29px;
  line-height: 29px;
  width: 165px;
  text-align: center;
  font-size: 13px;
  color: #333;
  float: left;
  background: #bdbaba;
  cursor: pointer;
  transition: background 0.1s ease-out;
}
.tab:hover {
  background: #e7eef2;
}
.tab_active {
  background: #e0e0e0;
}
.tab_active:hover {
  background: #e0e0e0;
}
.tab:first-child:after {
  content: ' ';
  display: block;
  float: right;
  width: 1px;
  height: 29px;
  background: #adadad;
}
.box {
  background: #e0e0e0;
  padding: 15px 17px 18px;
}
.search_wrapper {
  width: 79%;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.search_input {
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 34px;
  height: 34px;
  padding: 0 19px;
  background: #fff;
  color: #333;
  border: 1px solid #adadad;
  border-top-width: 2px;
  border-radius: 4px;
  outline: 0;
}
.search_button_wrapper {
  width: 21%;
  padding-left: 17px;
  display: inline-block;
  vertical-align: middle;
}
.suggest {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  border: 1px solid #adadad;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  z-index: 10;
  text-align: left;
}
.suggest_item {
  height: 26px;
  line-height: 26px;
  font-size: 13px;
  padding-left: 11px;
  transition: all 0.1s ease-out;
  cursor: pointer;
}
.suggest_item.hover {
  background: #e7eef2;
}
