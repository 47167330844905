.login {
  display: block;
  text-align: center;
  color: #306bb3;
  fill: #306bb3;
  transition: all 0.1s ease-out;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 0 10px;
}
.login:hover {
  color: #1c73c4;
  fill: #1c73c4;
}
.login_icon {
  margin-right: 8px;
}
.login_text,
.login_icon {
  display: inline-block;
  vertical-align: middle;
}
.wrapper {
  position: relative;
  text-align: left;
  height: 40px;
}
.popup {
  position: absolute;
  width: 100%;
  border: 1px solid transparent;
  z-index: 100;
  background: #fff;
  transition: border 0.1s ease-out;
}
.popup_active {
  border-color: #e0e0e0;
}
.popup_content {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: all 0.1s ease-out;
}
.popup_content_active {
  max-height: 160px;
  opacity: 1;
}
.header {
  font-size: 12px;
  font-weight: bold;
  color: #306bb3;
  height: 40px;
  line-height: 40px;
  position: relative;
  padding-left: 35px;
  transition: all 0.1s ease-out;
  cursor: pointer;
}
.header:hover {
  color: #2887e0;
}
.header:hover .icon {
  fill: #1c73c4;
}
.header:hover .arrow:before {
  border-color: #2887e0 transparent;
}
.icon {
  position: absolute;
  left: 9px;
  top: 10px;
  fill: #306bb3;
  transition: all 0.1s ease-out;
}
.arrow {
  position: absolute;
  right: 13px;
  top: 1px;
  width: 6px;
  height: 38px;
  transition: all 0.1s ease-out;
}
.popup_active .arrow {
  transform: rotate(-180deg);
}
.arrow:before,
.arrow:after {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 18px;
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 3px 3px 0;
}
.arrow:before {
  border-color: #306bb3 transparent;
}
.arrow:after {
  border-color: #fff transparent;
  margin-top: -1px;
}
.email {
  line-height: 28px;
  margin-bottom: 9px;
  padding-left: 35px;
  color: #2887e0;
  font-size: 13px;
}
.email_inner {
  overflow: hidden;
  text-overflow: ellipsis;
}
.menu {
  padding: 8px 0;
  border-top: 1px solid #e0e0e0;
}
.item {
  display: block;
  font-size: 13px;
  line-height: 26px;
  padding-left: 35px;
  transition: all 0.1s ease-out;
}
.item:hover {
  background: #e7eef2;
}
