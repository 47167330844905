.tabs
  cf()

.tab
  height:29px
  line-height: 29px
  width: 165px
  text-align: center
  font-size: 13px
  color: $black
  float: left
  background: $grey600
  cursor: pointer
  transition: background .1s ease-out

  &:hover
    background: $light-blue

  &_active
    background: $grey200

    &:hover
      background: $grey200

  &:first-child:after
    content: ' '
    display: block
    float: right
    width: 1px
    height: 29px
    background: $grey500