.cities
  border: 1px solid $grey200
  margin-bottom: 19px

.city
  display: block
  height: 44px
  line-height: 44px
  border-top: 1px solid $grey200
  font-size: 14px
  padding: 0 14px

  &:first-child
    border-top: 0

  &_active
    color: $dark-red

.spinner
  margin: 0 auto
  width: 28px
  height: 28px
  background: url(../../../styles/img/spin.svg)
