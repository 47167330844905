.scroll_top {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  cursor: pointer;
  transform: translateZ(0);
  z-index: 1;
}
.scroll_top:hover .highlight,
.scroll_top.hover .highlight {
  background: rgba(242,242,242,0.2);
}
.scroll_top:hover .button,
.scroll_top.hover .button {
  opacity: 1;
}
.narrow .button {
  width: 25px;
  height: 16px;
  left: auto;
  right: 10px;
  border: 0;
  bottom: 60px;
}
.narrow .highlight {
  width: 100%;
}
.highlight {
  width: 69px;
  height: 100%;
  transition: all 0.3s ease-out;
}
.button {
  position: absolute;
  left: 10px;
  bottom: 50px;
  width: 49px;
  height: 49px;
  line-height: 47px;
  text-align: center;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  opacity: 0.8;
  transition: all 0.1s ease-out;
  transform: translateZ(0);
}
.icon {
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  height: 16px;
  fill: #adadad;
}
