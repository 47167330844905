.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  background: #fff;
  box-shadow: 0 2px 1px rgba(0,0,0,0.2);
  z-index: 1000;
  transition: all 0.2s ease-out;
  margin-top: -83px;
  pointer-events: none;
  opacity: 0;
}
@supports (transform: translateY(0)) {
  .header {
    margin-top: 0;
    transform: translateY(-103%);
  }
}
.active {
  margin-top: 0;
  opacity: 1;
  pointer-events: all;
}
@supports (transform: translateY(0)) {
  .active {
    transform: translateY(0);
  }
}
.wrapper {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 1140px;
  display: flex;
  align-items: center;
}
.logo {
  padding-top: 6px;
  margin-right: auto;
}
.logoImage {
  width: 200px;
  height: 75px;
  display: block;
  background: 50% 50% no-repeat;
  background-size: contain;
  margin-top: -8px;
}
.phone {
  font-size: 22px;
  margin-right: 15px;
}
.phone_icon,
.phone_text {
  display: inline-block;
  vertical-align: middle;
}
.phone_icon {
  width: 23px;
  height: 23px;
  fill: #306bb3;
  margin-right: 8px;
}
.contacts {
  display: flex;
  align-items: center;
}
.cart {
  margin-left: 30px;
}
