@font-face {
  font-family: 'Rouble';
  src: url('../../../styles/fonts/Rouble-Regular.eot');
  src: url('../../../styles/fonts/Rouble-Regular.eot') format('embedded-opentype'),
          url('../../../styles/fonts/Rouble-Regular.woff2') format('woff2'),
          url('../../../styles/fonts/Rouble-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html, body
  padding: 0
  margin: 0

html
  font-family: $font-family
  color: $black
  min-height: 100%
  position: relative
  -webkit-text-size-adjust: 100%
  -ms-text-size-adjust: 100%

body
  overflow-y: scroll

img
  border: 0

*, *:before, *:after
  box-sizing: border-box

a
  color: inherit
  text-decoration: none

*::-ms-clear
  display: none


.wrapper
  wrapper()

.content
  container()

.content_inner
  span(12)
  position: relative

:global
  .r
    font-family: 'Rouble'

  .test
    stroke-width: 2px

