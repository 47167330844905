.cart {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #3b3b3b;
  padding: 10px;
  z-index: 2;
  line-height: 34px;
  transform: translateY(100%);
  transition: all 0.1s ease-out;
}
.cart_placeholder {
  transition: all 0.1s ease-out;
}
.active {
  transform: translateY(0);
}
.button {
  float: right;
}
.line {
  display: inline-block;
  vertical-align: middle;
}
.icon {
  fill: #306bb3;
  width: 30px;
  height: 27px;
}
.total {
  display: inline-block;
  vertical-align: middle;
  background: #fff;
  border-radius: 4px;
  height: 28px;
  line-height: 28px;
  width: 30px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  margin-left: 10px;
}
.summ {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-left: 10px;
}
