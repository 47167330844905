header_height = 33px
thumb_height = 54px
thumbs_height = thumb_height + 30px

.wrapper
  position: fixed
  left: 0
  top: 0
  right: 0
  bottom: 0
  text-align: center
  white-space: nowrap
  overflow: auto
  background: rgba(0, 0, 0, 0.85)
  z-index: 1000

.container
  position: absolute
  left: 95px
  top: header_height
  right: 95px
  bottom: thumbs_height

.photo_wrapper
  display: inline-block
  vertical-align: middle
  position: relative
  width: 100%
  height: 100%
  z-index: 2

  &:before
    content: ''
    display: inline-block
    vertical-align: middle
    height: 100%

.image
  display: inline-block
  vertical-align: middle
  margin: 0 auto
  max-width: 100%
  max-height: 100%

.photo
  display: inline-block
  vertical-align: middle
  position: relative
  max-width: 1024px
  max-height: 1024px

.header
  position: absolute
  height: header_height
  line-height: header_height
  width: 100%
  left: 0
  bottom: 100%
  background: #ffffff
  padding: 0 24px
  text-align: left
  z-index: 1

.left
  overflow: hidden
  text-overflow: ellipsis

.right
  float: right
  margin-left: 30px

.thumbs
  position: absolute
  padding-bottom: 25px
  bottom: 0
  left: 0
  right: 0
  text-align: center
  z-index: 3

.thumb
  display: inline-block
  vertical-align: middle
  height: thumb_height
  border: 1px solid $grey500
  margin: 0 3px

.active
  border-color: $red

.prev
  position: fixed
  left: 0
  top: 0
  bottom: 0
  width: 50%
  cursor: pointer

  .arrow_icon
    content: ''
    display: block
    position: absolute
    left: 15px
    top: 50%

.next
  position: fixed
  width: 100%
  top: 0
  bottom: 0
  cursor: pointer

  .arrow_icon
    content: ''
    display: block
    position: fixed
    right: 15px
    top: 50%

.arrow_icon
  width: 61px
  height: 111px
  transition: all .1s ease-out
  transform: translateZ(0)
  opacity: 0.5
  fill: $white
  margin-top: -55px

// IE z-index fix
.next, .prev
  transparentBG()

  &:hover .arrow_icon
    opacity: 1


.close
  position absolute
  right: 10px
  top: 10px
  z-index: 3
  cursor: pointer
  width: 73px
  height: 73px
  border: 1px solid $grey500
  text-align: center
  line-height: 73px
  border-radius: 4px
  transition: all .1s ease-out

  &:hover
    border-color: $dark-red

  &_icon
    display: inline-block
    vertical-align: middle
    stroke: $white
    width: 42px
    height: 42px
    transition: all .1s ease-out

    .close:hover &
      stroke: $dark-red

.loading
  position: absolute
  left: 50%
  top: 50%
  margin-left: -20px
  margin-top: -20px
  width: 40px
  height: 40px
  background: url(../../../styles/img/spin.svg)