.toggler {
  display: none;
}
.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 100%;
  top: 0;
  background: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: all 0.15s cubic-bezier(0.8, 0, 0.2, 1);
  transform: translateX(0);
  z-index: 10;
  -webkit-overflow-scrolling: touch;
}
.toggler:checked + .wrapper {
  transform: translateX(-100%);
}
.menu {
  width: 200%;
  position: relative;
  transition: all 0.35s ease-in-out;
}
.menu.citySelect {
  transform: translateX(-50%);
}
.content {
  padding: 18px 10px;
  width: 50%;
  float: left;
}
.items {
  border: 1px solid #e0e0e0;
  margin-bottom: 19px;
}
.item {
  display: block;
  height: 44px;
  line-height: 43px;
  border-top: 1px solid #e0e0e0;
  font-size: 1px;
}
.item:first-child {
  border-top: 0;
}
.icon {
  fill: #306bb3;
  display: block;
  margin: 0 auto;
}
.icon_wrapper {
  display: inline-block;
  vertical-align: middle;
  width: 40px;
}
.text {
  font-size: 13px;
  display: inline-block;
  vertical-align: middle;
}
.text_padding {
  padding-left: 14px;
}
