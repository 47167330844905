.link {
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
  color: #333;
  text-decoration: none;
  margin-left: 15px;
}
.link:hover,
.link_active {
  color: #2887e0;
}
.link:first-child {
  margin-left: 0;
}
