.wrapper {
  position: relative;
}
.icon {
  position: absolute;
  top: 7px;
  right: 9px;
  fill: #717171;
  pointer-events: none;
}
.clear {
  position: absolute;
  top: 12px;
  right: 50px;
  fill: #717171;
}
