.wrapper
  position: relative

.icon
  position: absolute
  top: 7px
  right: 9px
  fill: $grey700
  pointer-events: none

.clear
  position: absolute
  top: 12px
  right: 50px
  fill: $grey700
