.header
  padding-top: 4px
  margin-bottom: 58px

.logoImage
  width: 200px
  height: 75px
  display: block
  background: 50% 50% no-repeat
  background-size: contain
  margin-top: -7px

.phone
  font-size: 22px
  margin-right: 15px

  &_icon, &_text
    display: inline-block
    vertical-align: middle

  &_icon
    width: 23px
    height: 23px
    fill: $dark-blue
    margin-right: 8px


.row
  display: flex;
  align-items center

.aside
  margin-left: auto
  display: flex;
  align-items center

.cart
  margin-left: auto
