.header {
  padding-top: 4px;
  margin-bottom: 58px;
}
.logoImage {
  width: 200px;
  height: 75px;
  display: block;
  background: 50% 50% no-repeat;
  background-size: contain;
  margin-top: -7px;
}
.phone {
  font-size: 22px;
  margin-right: 15px;
}
.phone_icon,
.phone_text {
  display: inline-block;
  vertical-align: middle;
}
.phone_icon {
  width: 23px;
  height: 23px;
  fill: #306bb3;
  margin-right: 8px;
}
.row {
  display: flex;
  align-items: center;
}
.aside {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.cart {
  margin-left: auto;
}
