.wrapper
  height: 45px
  background-color: $grey100
  border-bottom: 1px solid $grey500

.left
.right
.center
  height: 45px - 1
  line-height: 45px - 1
  font-size: 1px

.left
  position: absolute
  left: 10px

.right
  position: absolute
  text-align: right
  right: 10px

.center
  text-align: center

.logo
  width: 100px
  height: 38px
  display: inline-block
  vertical-align: middle
  background: 50% 50% no-repeat
  background-size: contain

.title
  font-size: 16px
  font-weight: bold
