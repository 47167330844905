.scroll_top
  position: fixed
  left: 0
  top: 0
  height: 100%
  cursor: pointer
  transform: translateZ(0)
  z-index: 1
  &:hover, &.hover
    .highlight
      background: rgba($grey100, .2)
    .button
      opacity: 1

.narrow
  .button
    //display: none
    width: 25px
    height: 16px
    left: auto
    right: 10px
    border: 0
    bottom: 60px

  .highlight
    width: 100%

.highlight
  width: 49px + 20px
  height: 100%
  transition: all .3s ease-out

.button
  position: absolute
  left: 10px
  bottom: 50px
  width: 49px
  height: 49px
  line-height: 47px
  text-align: center
  border: 1px solid $grey200
  border-radius: 3px
  opacity: 0.8
  transition: all .1s ease-out
  transform: translateZ(0)

.icon
  display: inline-block
  vertical-align: middle
  width: 25px
  height: 16px
  fill: $grey500