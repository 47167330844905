.cities {
  border: 1px solid #e0e0e0;
  margin-bottom: 19px;
}
.city {
  display: block;
  height: 44px;
  line-height: 44px;
  border-top: 1px solid #e0e0e0;
  font-size: 14px;
  padding: 0 14px;
}
.city:first-child {
  border-top: 0;
}
.city_active {
  color: #1c73c4;
}
.spinner {
  margin: 0 auto;
  width: 28px;
  height: 28px;
  background: url("../../../styles/img/spin.svg");
}
