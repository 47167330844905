@font-face {
  font-family: 'Rouble';
  src: url('../../../styles/fonts/Rouble-Regular.eot');
  src: url('../../../styles/fonts/Rouble-Regular.eot') format('embedded-opentype'),
          url('../../../styles/fonts/Rouble-Regular.woff2') format('woff2'),
          url('../../../styles/fonts/Rouble-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html, body
  padding: 0
  margin: 0

html
  font-family: $font-family
  color: $black
  min-height: 100%
  position: relative
  -webkit-text-size-adjust: 100%
  -ms-text-size-adjust: 100%

//body
//  overflow: hidden

img
  border: 0

*, *:before, *:after
  box-sizing: border-box

a
  color: inherit
  text-decoration: none

summary::-webkit-details-marker
  display: none

//:global(#root), .page
//  height: 100%
//  width: 100%

//.wrapper
//  width: 100%
//  height: 100%
//  overflow-y: scroll

.content
  padding: 10px 10px 0

//.inner
//  position: relative
//  min-height: 100%
